import { onMounted, onUnmounted, ref } from 'vue';

export function useViewport() {
  const isMobile = ref(false);

  const updateViewport = () => {
    isMobile.value = window.innerWidth < 600;
  };

  onMounted(() => {
    updateViewport();
    window.addEventListener('resize', updateViewport);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updateViewport);
  });

  return { isMobile };
}
